<template>
  <div class="card p-1">
    <div class="row">
      <div class="col-12 col-lg-6 pt-1">
        <div class="row justify-content-center text-center">
            <div class="d-flex flex-column justify-content-center align-items-center mx-3">
              <p-avatar round size="42px" color="light-success">
                <p-icon size="24px" name="feather-dollar-sign" />
              </p-avatar>
              <h1>
                {{ revenueTotal }}
              </h1>
              <p>
                Total Revenue
              </p>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center mx-3">
              <p-avatar round size="42px" color="light-warning">
                <p-icon size="24px" name="feather-dollar-sign" />
              </p-avatar>
              <h1>
                {{ totalCredit }}
              </h1>
              <p>
                Total Credits
              </p>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center mx-3">
              <p-avatar round size="42px" color="light-warning">
                <p-icon size="24px" name="feather-dollar-sign" />
              </p-avatar>
              <h1>
                {{ totalDue }}
              </h1>
              <p>
                Total Due
              </p>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center mx-3">
              <p-avatar round size="42px" color="light-primary">
                <p-icon size="24px" name="feather-dollar-sign" />
              </p-avatar>
              <h1>
                {{ revenuePerItem }}
              </h1>
              <p>
                Revenue Per Item
              </p>
            </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 pt-1 px-3">
        <dx-chart
            id="chartMonthlyProfitPerITem"
            :data-source="monthlyProfitPerItem"
            palette="Ocean"
        >
          <dx-series
            argument-field="timeInfo"
            value-field="invoiceData"
            name="Monthly Revenue/Item"
            type="bar"
          />
          <dx-size :height="250" />
          <dx-legend vertical-alignment="bottom" horizontal-alignment="center" />
          <dx-tooltip :enabled="true" location="edge" />
        </dx-chart>
      </div>
      <div class="col-12 col-lg-6 p-3">
        <dx-pie-chart
          id="chartRevenueDistributionByChargeType"
          :data-source="revenueDistributionByChargeType"
          resolve-label-overlapping="shift"
          palette="Material"
          @point-click="pointClickHandler($event)"
          @legend-click="legendClickHandler($event)"
        >
          <dx-title text="Revenue Distribution by Charge Type" />
          <dx-series argument-field="chargeType" value-field="invoiceData">
            <dx-label :visible="true" :customize-text="formatLabel" position="columns">
              <dx-connector :visible="true" :width="0.5" />
              <dx-font :size="16" />
            </dx-label>
          </dx-series>
          <DxSize :width="600" :height="600" />
          <dx-legend
            :column-count="4"
            orientation="horizontal"
            item-text-position="right"
            horizontal-alignment="center"
            vertical-alignment="bottom"
          />
          <dx-export :enabled="true" />
        </dx-pie-chart>
      </div>
      <div class="col-12 col-lg-6 p-3">
        <dx-data-grid
          id="revenueDistributionByCostGroupGrid"
          ref="revenueDistributionByCostGroupRef"
          :data-source="revenueDistributionByCostGroup"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :row-alternation-enabled="false"
          :hover-state-enabled="true"
        >
          <dx-column data-field="timeInfo" caption="Type" />
          <dx-column data-field="invoiceData" caption="Total" cell-template="amountTemplate" />
          <template #amountTemplate="{ data }">
            {{ getCurrencyFormat(data.value) }}
          </template>
        </dx-data-grid>
      </div>
    </div>
  </div>
</template>

<script>
import basicStatsService from '@/http/requests/stats/basicStatsService'
import { currencyFormatter } from '@core/utils/filter'
import {
  DxChart,
  DxSeries,
  DxLegend,
  DxTooltip,
  DxLabel,
  DxFont,
  DxTitle,
} from 'devextreme-vue/chart'
import DxPieChart, {
  DxConnector,
  DxSize,
} from 'devextreme-vue/pie-chart'

export default {
  components: {
    DxChart,
    DxPieChart,
    DxSeries,
    DxLegend,
    DxTooltip,
    DxSize,
    DxConnector,
    DxLabel,
    DxFont,
    DxTitle,
  },
  props: {
    requestInputs: {
      type: Object,
      required: true,
    },
    activeTab: {
      type: String,
      required: true,
    },
    filterKey: {
      type: Number,
      required: true,
    },

  },
  data() {
    return {
      revenueTotal: 0,
      revenuePerItem: 0,
      totalCredit: 0,
      totalDue: 0,
      monthlyProfitPerItem: [],
      revenueDistributionByChargeType: [],
      revenueDistributionByCostGroup: [],
      revenue: {
        key: 'revenue',
        title: 'Revenue',
        palette: 'Ocean',
      },
    }
  },
  watch: {
    requestInputs: {
      handler() {
        if (this.activeTab === this.revenue.title) {
          this.loadBasicData()
        }
      },
    },
    filterKey: {
      handler() {
        if (this.activeTab === this.revenue.title) {
          this.loadBasicData()
        }
      },
    },
  },
  mounted() {
    this.loadBasicData()
  },
  methods: {
    getRequest(timeInterval, statType) {
      return {
        accountNo: this.requestInputs.accountNo,
        companyId: null,
        storeId: null,
        warehouseId: null,
        beginDate: this.requestInputs.beginDate,
        endDate: this.requestInputs.endDate,
        timeInterval: timeInterval,
        statsType: statType,
        q: this.requestInputs.q,
        top_n: this.requestInputs.top_n,
      }
    },
    getCurrencyFormat(value) {
      return currencyFormatter(value)
    },
    getCurrencyFormatNoSign(value) {
      if (!value) return value
      const sansDec = value.toFixed(2)
      const formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      return formatted
    },
    formatLabel(pointInfo) {
      return `${pointInfo.valueText} (${pointInfo.percentText})`
    },
    pointClickHandler(e) {
      this.toggleVisibility(e.target)
    },
    legendClickHandler(e) {
      const arg = e.target
      const item = e.component.getAllSeries()[0].getPointsByArg(arg)[0]

      this.toggleVisibility(item)
    },
    toggleVisibility(item) {
      // eslint-disable-next-line no-unused-expressions
      item.isVisible() ? item.hide() : item.show()
    },
    loadBasicData() {
      const revenueDistribution = this.getRequest('top_n', 'revenue_distribution_by_charge_type')
      basicStatsService.getBasicStats(revenueDistribution).then(result => {
        const { data } = result
        if (!data.includes(null)) {
          this.revenueDistributionByChargeType = data
        }
      })
      const revenueDistributionByGroup = this.getRequest('top_n', 'revenue_distribution_by_cost_group_name')
      basicStatsService.getBasicStats(revenueDistributionByGroup).then(result => {
        const { data } = result
        if (!data.includes(null)) {
          this.revenueDistributionByCostGroup = data
        }
      })
      const revenueTotal = this.getRequest('top_n', 'revenue_total')
      basicStatsService.getBasicStats(revenueTotal).then(result => {
        const { data } = result
        if (!data.includes(null)) {
          this.revenueTotal = this.getCurrencyFormat(data[0].invoiceData)
        }
        const itemCount = this.getRequest('top_n', 'shipment_item_count')
        basicStatsService.getBasicStats(itemCount).then(result1 => {
          if (!data.includes(null) && !result1.data.includes(null)) {
            this.revenuePerItem = this.getCurrencyFormat(data[0].invoiceData / result1.data[0].data)
          }
        })
      })
      const totalDue = this.getRequest('top_n', 'invoice_total_due')
      basicStatsService.getBasicStats(totalDue).then(result => {
        const { data } = result
        if (!data.includes(null)) {
          this.totalDue = this.getCurrencyFormat(data[0]?.invoiceData)
        }
      })
      const totalCredit = this.getRequest('top_n', 'customer_balance_used')
      basicStatsService.getBasicStats(totalCredit).then(result => {
        const { data } = result
        if (!data.includes(null)) {
          this.totalCredit = this.getCurrencyFormat(data[0]?.invoiceData)
        }
      })
      const monthlyProfitPerItem = this.getRequest('top_n', 'monthly_profit_per_item')
      basicStatsService.getBasicStats(monthlyProfitPerItem).then(result => {
        const { data } = result
        this.monthlyProfitPerItem.length = 0
        if (!data.includes(null)) {
          data.forEach(e => {
            this.monthlyProfitPerItem.push({ timeInfo: e.timeInfo, invoiceData: Number(this.getCurrencyFormatNoSign(e.invoiceData)) })
          })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
