<template>
  <div class="row m-half">
    <div v-for="chart in unloadedItems.charts" :key="chart.id" class="col-lg-4">
      <dx-chart
        :data-source="dataSources[chart.id]"
        :palette="chart.palette"
        width="100%"
      >
        <dx-title :text="chart.title" />
        <dx-series
          argument-field="timeInfo"
          value-field="data"
          :name="formatLegendName(chart.requestData.timeInterval)"
          type="bar"
        />
        <dx-legend vertical-alignment="bottom" horizontal-alignment="center" />
        <dx-export :enabled="true" />
        <dx-tooltip :enabled="true" location="edge" />
      </dx-chart>
    </div>
  </div>
</template>

<script>
import { Notify } from '@/@robustshell/utils'
import basicStatsService from '@/http/requests/stats/basicStatsService'
import {
  DxChart,
  DxSeries,
  DxLegend,
  DxExport,
  DxTooltip,
  DxTitle,
} from 'devextreme-vue/chart'

export default {
  components: {
    DxChart,
    DxSeries,
    DxLegend,
    DxExport,
    DxTooltip,
    DxTitle,
  },
  props: {
    requestInputs: {
      type: Object,
      required: true,
    },
    activeTab: {
      type: String,
      required: true,
    },
    filterKey: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dataSource: [],
      dataSourceTemp: [],
      dataSources: {},
      invoiceKey: 'invoice',
      unloadedItems: {
        key: 'unloaded-items',
        icon: 'DownloadIcon',
        tooltip: 'Unloaded Package Metrics',
        title: 'Unloaded Packages',
        charts: [
          {
            palette: 'Harmony Light',
            title: 'Daily Unloaded Package Count',
            id: 'dailyUnloadedPackageData',
            requestData: {
              timeInterval: 'day',
              statType: 'unloaded_item_count',
            },
          },
          {
            palette: 'Harmony Light',
            title: 'Weekly Unloaded Package Count',
            id: 'weeklyUnloadedPackageData',
            requestData: {
              timeInterval: 'week',
              statType: 'unloaded_item_count',
            },
          },
          {
            palette: 'Harmony Light',
            title: 'Monthly Unloaded Package Count',
            id: 'monthlyUnloadedPackageData',
            requestData: {
              timeInterval: 'month',
              statType: 'unloaded_item_count',
            },
          },
          {
            palette: 'Harmony Light',
            title: 'Hour of Day Unloaded Package Count',
            id: 'hourOfDayUnloadedPackageData',
            requestData: {
              timeInterval: 'hour',
              statType: 'unloaded_item_count',
            },
          },
          {
            palette: 'Harmony Light',
            title: 'Day of the Week Unloaded Package Count',
            id: 'dayOfWeekUnloadedPackageData',
            requestData: {
              timeInterval: 'dow',
              statType: 'unloaded_item_count',
            },
          },
        ],
      },
    }
  },
  watch: {
    requestInputs: {
      handler() {
        if (this.activeTab === this.unloadedItems.title) {
          this.updateDataSource()
        }
      },
    },
    filterKey: {
      handler() {
        if (this.activeTab === this.unloadedItems.title) {
          this.updateDataSource()
        }
      },
    },
  },
  async mounted() {
    this.updateDataSource()
  },
  methods: {
    async loadBasicData(chart) {
      const payload = {
        accountNo: this.requestInputs.accountNo,
        companyId: null,
        storeId: null,
        warehouseId: null,
        beginDate: this.requestInputs.beginDate,
        endDate: this.requestInputs.endDate,
        timeInterval: chart.requestData.timeInterval,
        statsType: chart.requestData.statType,
        q: this.requestInputs.q,
        top_n: this.requestInputs.top_n,
      }
      const { data } = await basicStatsService.getBasicStats(payload)
      return data
    },
    formatLegendName(e) {
      if (e === 'dow') return 'DAY OF WEEK'
      if (e === 'top_n') return 'TOP 10 ACCOUNT'
      return e.toUpperCase()
    },
    updateDataSource() {
      const sources = {}
      const promises = this.unloadedItems.charts.map(chart => this.loadBasicData(chart).then(data => {
        sources[chart.id] = data
      }))
      Promise.all(promises).then(() => {
        this.dataSources = sources
      }).catch(error => {
        Notify.warning(`Charts data load failed. ${error}`)
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
