<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0 mb-1">
        <div class="card mb-0">
          <div class="d-flex align-items-center p-1">
            <div v-if="hasPermission" class="px-half">
              <dx-util-text-box
                v-model="accountNo"
                :show-clear-button="true"
                mode="text"
                placeholder="Account or Suite No"
                @key-down="loadBasicStatsByAccount"
              />
            </div>
            <div class="px-half">
              <dx-util-text-box
                v-model="q"
                :show-clear-button="true"
                placeholder="Code"
                @key-down="loadBasicStatsByCode"
              />
            </div>
            <div class="px-half">
              <dx-util-select-box
                v-model="selectedDateRange"
                :data-source="dateRangesList"
                :drop-down-options="{width: '180px'}"
                display-expr="text"
                value-expr="value"
                @value-changed="setDateRanges"
              />
            </div>
            <div v-if="isCustomDateRange" class="px-half p-adjusted">
              <dx-util-date-box v-model="beginDate"
                :max="endDate" type="date"
                date-serialization-format="yyyy-MM-dd"
                label="Begin Date"
              />
            </div>
            <div v-if="isCustomDateRange" class="px-half p-adjusted">
              <dx-util-date-box v-model="endDate"
                :min="beginDate" type="date"
                date-serialization-format="yyyy-MM-dd"
                label="End Date"
              />
            </div>
            <div class="px-half">
              <dx-util-button
                id="resetForm"
                icon="refresh"
                hint="Reset Form Data"
                type="default"
                :element-attr="{ class: 'btn-warning' }"
                @click="resetFilteringData"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 px-0">
        <div class="card">
          <dx-util-tab-panel :animation-enabled="true" :defer-rendering="true" :show-nav-buttons="true" :on-selection-changed="onTabSelect">
            <dx-util-panel-item title="Uploaded Items" icon="icon icon-psh-package-3">
              <template #default>
                <uploaded-items-charts :request-inputs="requestInputs" :active-tab="activeTab" :filter-key="filterKey" />
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item title="Unloaded Packages" icon="icon icon-psh-cargo4">
              <template #default>
                <unloaded-items-charts :request-inputs="requestInputs" :active-tab="activeTab" :filter-key="filterKey" />
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item title="FBA Shipped Items" icon="icon icon-feather-box">
              <template #default>
                <fba-shipped-items-charts :request-inputs="requestInputs" :active-tab="activeTab" :filter-key="filterKey" />
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item title="FBM Shipped Items" icon="icon icon-psh-outbound">
              <template #default>
                <fbm-charts-tab :request-inputs="requestInputs" :active-tab="activeTab" :filter-key="filterKey" />
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item v-if="hasInvoicePermission" title="Invoice" icon="bi bi-receipt">
              <template #default>
                <invoice-charts :request-inputs="requestInputs" :active-tab="activeTab" :filter-key="filterKey" />
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item v-if="hasInvoicePermission" title="Revenue" icon="icon icon-feather-dollar-sign">
              <template #default>
                <revenue-statistic :request-inputs="requestInputs" :active-tab="activeTab" :filter-key="filterKey" />
              </template>
            </dx-util-panel-item>
          </dx-util-tab-panel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import predefinedDateRangesEnum, { getPredefinedDateRangesList } from '@/enums/predefinedDateRanges.enum.js'
import useDateRanges from '@/@core/composables/useDateRanges.js'
import RevenueStatistic from './components/RevenueStatistic.vue'
import UploadedItemsCharts from './components/UploadedItemsCharts.vue'
import UnloadedItemsCharts from './components/UnloadedItemsCharts.vue'
import InvoiceCharts from './components/InvoiceCharts.vue'
import FBAShippedItemsCharts from './components/FBAShippedItemsCharts.vue'
import FbmChartsTab from './components/FbmChartsTab.vue'

export default {
  components: {
    'fbm-charts-tab': FbmChartsTab,
    'revenue-statistic': RevenueStatistic,
    'uploaded-items-charts': UploadedItemsCharts,
    'unloaded-items-charts': UnloadedItemsCharts,
    'fba-shipped-items-charts': FBAShippedItemsCharts,
    'invoice-charts': InvoiceCharts,
  },
  data() {
    const today = new Date()
    const last90days = new Date()
    last90days.setDate(today.getDate() - 90)
    last90days.setHours(0, 0, 0, 0)
    const plusOneDay = new Date(today)
    plusOneDay.setDate(today.getDate() + 1)
    plusOneDay.setHours(0, 0, 0, 0)
    return {
      selectedIndex: 0,
      isServingTenant: true,
      accountNo: '',
      q: '',
      selectedDateRange: predefinedDateRangesEnum.LAST90DAYS.value,
      dateRangesList: getPredefinedDateRangesList(),
      beginDate: last90days,
      endDate: plusOneDay,
      showCustomDateSelection: false,
      updateNeeded: {
        'Uploaded Items': true,
        'Unloaded Packages': true,
        'FBA Shipped Items': true,
        'FBM Shipped Items': true,
        Invoice: true,
        Revenue: true,
      },
      rerenderKey: 0,
      filterKey: 0,
      activeTab: 'Uploaded Items',
    }
  },
  setup() {
    const { setDateRanges, startDate, isCustomDateRange } = useDateRanges()
    return { setDateRanges, startDate, isCustomDateRange }
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    hasInvoicePermission() {
      return this.$can('read', 'resource_dashboard_basic_stats_invoice')
    },
    requestInputs() {
      return {
        accountNo: this.accountNo,
        beginDate: this.beginDate,
        endDate: this.endDate,
        q: this.q,
        top_n: 10,
      }
    },
  },
  watch: {
    accountNo: {
      handler() {
        Object.keys(this.updateNeeded).forEach(key => {
          this.updateNeeded[key] = true
        })
        this.updateNeeded[this.activeTab] = false
      },
    },
    q: {
      handler() {
        Object.keys(this.updateNeeded).forEach(key => {
          this.updateNeeded[key] = true
        })
        this.updateNeeded[this.activeTab] = false
      },
    },
    beginDate: {
      handler() {
        Object.keys(this.updateNeeded).forEach(key => {
          this.updateNeeded[key] = true
        })
        this.updateNeeded[this.activeTab] = false
      },
    },
    startDate(newValue) {
      this.beginDate = newValue
    },
    endDate: {
      handler() {
        Object.keys(this.updateNeeded).forEach(key => {
          this.updateNeeded[key] = true
          this.updateNeeded[this.activeTab] = false
        })
      },
    },
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    if (userData.tenantType !== 'SERVING') {
      this.isServingTenant = false
    }
  },
  methods: {
    onTabSelect(e) {
      this.activeTab = e.addedItems[0].title
      if (this.updateNeeded[this.activeTab]) {
        this.filterKey += 1
        this.updateNeeded[this.activeTab] = false
      }
    },
    resetFilteringData() {
      this.accountNo = ''
      this.q = ''
      this.top_n = 10
      this.selectedDateRange = predefinedDateRangesEnum.LAST90DAYS.value
      const today = new Date()
      const plusOneDay = new Date()
      const last90days = new Date()
      last90days.setDate(today.getDate() - 90)
      last90days.setHours(0, 0, 0, 0)
      plusOneDay.setDate(plusOneDay.getDate() + 1)
      this.beginDate = last90days
      this.endDate = plusOneDay
    },
    loadBasicStatsByAccount(e) {
      if (e.event.keyCode !== 13) return
      this.accountNo = e.event.target.value
    },
    loadBasicStatsByCode(e) {
      if (e.event.keyCode !== 13) return
      this.q = e.event.target.value
    },

  },
}
</script>
<style lang="scss">
.p-adjusted {
  margin-top: -8px;
}
.dx-tab-selected {
  background-color: #1d9bd9;
  .dx-tab-text {
    color: #dceaf1;
  }
  .dx-icon {
    color: #dceaf1;
  }
}

.dx-tabs {
  border-radius: 4px 4px 0 0;
}
.dx-tab {
  border-radius: 4px 4px 0 0;
}
</style>
