<template>
  <div class="row p-1">
    <div
      v-for="(chart, i) in fbmShippedItems.charts"
      :key="chart.id"
      :class="i === 0 ? 'col-lg-12 p-half' : 'col-lg-6 p-half'"
    >
      <dx-chart
        :data-source="dataSources[chart.id]"
        :palette="chart.palette"
      >
        <dx-title :text="chart.title" />
        <dx-series-template name-field="type" />
        <dx-common-series-settings
          argument-field="key"
          value-field="value"
          type="bar"
          :ignore-empty-points="false"
        />
        <dx-legend vertical-alignment="bottom" horizontal-alignment="center" />
        <dx-export :enabled="true" />
        <dx-tooltip :enabled="true" location="edge" />
      </dx-chart>
    </div>
  </div>
</template>

<script>
import { Notify } from '@/@robustshell/utils'
import basicStatsService from '@/http/requests/stats/basicStatsService'
import {
  DxChart,
  DxLegend,
  DxExport,
  DxSeriesTemplate,
  DxCommonSeriesSettings,
  DxTooltip,
  DxTitle,
} from 'devextreme-vue/chart'

export default {
  components: {
    DxChart,
    DxSeriesTemplate,
    DxCommonSeriesSettings,
    DxLegend,
    DxExport,
    DxTooltip,
    DxTitle,
  },
  props: {
    requestInputs: {
      type: Object,
      required: true,
    },
    activeTab: {
      type: String,
      required: true,
    },
    filterKey: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dataSources: {},
      fbmShippedItems: {
        key: 'fbm-shipped-items',
        icon: 'GiftIcon',
        tooltip: 'FBM Shipped Item Metrics',
        title: 'FBM Shipped Items',
        charts: [
          {
            palette: 'Material',
            title: 'Daily FBM Shipped Items',
            id: 'dailyFBMShippedItemData',
            requestData: {
              timeInterval: 'day',
              statType: 'fbm_shipped_counts',
            },
          },
          {
            palette: 'Material',
            title: 'Weekly FBM Shipped Items',
            id: 'weeklyFBMShippedItemData',
            requestData: {
              timeInterval: 'week',
              statType: 'fbm_shipped_counts',
            },
          },
          {
            palette: 'Material',
            title: 'Monthly FBM Shipped Items',
            id: 'monthlyFBMShippedItemData',
            requestData: {
              timeInterval: 'month',
              statType: 'fbm_shipped_counts',
            },
          },
        ],
      },
    }
  },
  watch: {
    requestInputs: {
      handler() {
        if (this.activeTab === this.fbmShippedItems.title) {
          this.updateDataSource()
        }
      },
      deep: true,
      immediate: false,
    },
    filterKey: {
      handler() {
        if (this.activeTab === this.fbmShippedItems.title) {
          this.updateDataSource()
        }
      },
    },
  },
  mounted() {
    this.updateDataSource()
  },
  methods: {
    async loadBasicData(chart) {
      const payload = {
        accountNo: this.requestInputs.accountNo,
        companyId: null,
        storeId: null,
        warehouseId: null,
        beginDate: this.requestInputs.beginDate,
        endDate: this.requestInputs.endDate,
        timeInterval: chart.requestData.timeInterval,
        statsType: chart.requestData.statType,
        q: this.requestInputs.q,
        top_n: this.requestInputs.top_n,
      }
      const { data } = await basicStatsService.getCombinedFbmStats(payload)
      return data
    },
    formatLegendName(e) {
      if (e === 'dow') return 'DAY OF WEEK'
      if (e === 'top_n') return 'TOP 10 ACCOUNT'
      return e.toUpperCase()
    },
    updateDataSource() {
      const sources = {}
      const promises = this.fbmShippedItems.charts.map(chart => this.loadBasicData(chart).then(data => {
        sources[chart.id] = data
      }))
      Promise.all(promises).then(() => {
        this.dataSources = sources
      }).catch(error => {
        Notify.warning(`Charts data load failed. ${error}`)
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
