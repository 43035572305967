import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {

  getBasicStats(request) {
    const promise = new Promise((resolve, reject) => {
      axios.post(endpoints.basicStats.Controller.getBasicStats(), request)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  getCombinedFbmStats(request) {
    const promise = new Promise((resolve, reject) => {
      axios.post(endpoints.basicStats.Controller.getCombinedFbmStats(), request)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  getDashboardStats(request) {
    const promise = new Promise((resolve, reject) => {
      axios.post(endpoints.basicStats.Controller.getDashboardStats(), request)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  getDashboardStatsTopN(request) {
    const promise = new Promise((resolve, reject) => {
      axios.post(endpoints.basicStats.Controller.getDashboardStatsTopN(), request)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  getCombinedData(request) {
    const promise = new Promise((resolve, reject) => {
      axios.post(endpoints.basicStats.Controller.getCombinedData(), request)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  getWorkerPerformance(request) {
    const promise = new Promise((resolve, reject) => {
      axios.post(endpoints.basicStats.Controller.getWorkerPerformance(), request)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async getStatsByList(data) {
    const result = await axios.post(endpoints.basicStats.Controller.getStatsByList(), data)
    return result.data.body
  },
}
